import React from 'react'

import Layout from 'components/Layout'

const NoPage = () => (
  <Layout>
    <oma-grid-row>
      <section className='section'>
        <h1>Sidan kunde inte hittas</h1>
        <p>Wops! Den här sidan kunde inte hittas!</p>
      </section>
    </oma-grid-row>
  </Layout>
)

export default NoPage
